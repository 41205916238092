body {
  font-family: sans-serif;
  margin: 0;
  // overflow: hidden;
}

#container,
#css_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

#smooth-content {
  height: 1800vh;
}

#percentage {
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 100;
  font-size: 16px;
  background-color: transparent;
  color: white;
  border: 0;
}

#guide {
  position: fixed;
  bottom: 10px;
  left: 20px;
  z-index: 100;
  font-size: 16px;
  background-color: transparent;
  color: white;
  border: 0;
  opacity:0;
  -webkit-transition: all 0.3s ease;-moz-transition: all 0.3s ease;-ms-transition: all 0.3s ease;-o-transition: all 0.3s ease;transition: all 0.3s ease;
}

div {
  color: white !important;
  font-family: sans-serif;
}

body {
  margin: 0px;
  font-family: monospace;
  color: white;
}

canvas,
#container,
#css_container {
  position: fixed;
  top: 0;
  left: 0;
}

#scroll_container {
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  scroll-behavior: smooth;
  z-index: 99;
  position: absolute;
}

section {
  min-height: 1800vh;
  height: 1800vh;
}

#scrollProgress {
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 99;
  font-size: 3vh;
}

#percent_bar {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 10px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
  display: none;
}


.navigation
{
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  z-index: 9;



  .item
  {
    display:flex;
    align-items:center;
    flex-direction:row;
    padding:20px 0;
    position: relative;

    .label
    {
      margin-right:15px;
      opacity:0;
      visibility: hidden;
      position: absolute;
      right:100%;
      font-weight:bold;
      font-size:12px;
      white-space: nowrap;
      -webkit-transition: all 0.3s ease;-moz-transition: all 0.3s ease;-ms-transition: all 0.3s ease;-o-transition: all 0.3s ease;transition: all 0.3s ease;
    }

    .filler
    {
      display:none;
    }

    .bar
    {
      width:4px;
      height:4px;
      background-color: rgba(255,255,255,0.3);
      margin:0 10px;
      overflow-x:hidden;
      position: relative;
      -webkit-transition: all 0.3s ease;-moz-transition: all 0.3s ease;-ms-transition: all 0.3s ease;-o-transition: all 0.3s ease;transition: all 0.3s ease;
    }

    .number
    {
      display:none;

      @media(min-width: 1025px){
        display:block;
      }
      &.before
      {
        opacity:0.3;
        -webkit-transition: all 0.3s ease;-moz-transition: all 0.3s ease;-ms-transition: all 0.3s ease;-o-transition: all 0.3s ease;transition: all 0.3s ease;
      }

      &.after
      {
        opacity:0;
        -webkit-transition: all 0.3s ease;-moz-transition: all 0.3s ease;-ms-transition: all 0.3s ease;-o-transition: all 0.3s ease;transition: all 0.3s ease;
      }
    }

    &:not(.active):hover
    {
      cursor:pointer;

      .filler
      {
        display:block;
      }

      @media(min-width: 1025px){
        .label
        {
          opacity:1;
          visibility: visible;
        }
      }

      .bar
      {
        background-color: rgba(255,255,255,1);
      }
      .number
      {
        &.before
        {
          opacity:1;
        }
      }
    }

    &.active
    {
      .number
      {
        &.before
        {
          opacity:0;
        }

        &.after
        {
          opacity:1;
        }
      }

      .bar
      {
        height:60px;

        @media(min-width: 1025px){
          height:170px;
        }
        .filler
        {
          background-color:#FFF;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width:100%;
          display:block;
          -webkit-transition: all 0.3s ease;-moz-transition: all 0.3s ease;-ms-transition: all 0.3s ease;-o-transition: all 0.3s ease;transition: all 0.3s ease;
        }
      }

      &.sections-1
      {
        .bar
        {
          .filler
          {
            top:0;
            height:100%;
          }
        }
      }

      &.sections-2
      {
        .bar
        {
          .filler
          {
            top:0;
            height:50%;
          }
        }

        &.section-2-active
        {
          .bar
          {
            .filler
            {
              top:0;
              height:100%;
            }
          }
        }
      }

      &.sections-4
      {
        .bar
        {
          .filler
          {
            top:0;
            height:25%;
          }
        }

        &.section-2-active
        {
          .bar
          {
            .filler
            {
              top:0;
              height:50%;
            }
          }
        }
        &.section-3-active
        {
          .bar
          {
            .filler
            {
              top:0;
              height:75%;
            }
          }
        }
        &.section-4-active
        {
          .bar
          {
            .filler
            {
              top:0%;
              height:100%;
            }
          }
        }
      }
    }
  }
}

body.show-scroll
{
  #guide
  {
    opacity:1;
  }
}
